
import {defineComponent, h} from "vue";
import type {MenuOption} from 'naive-ui'
import {RouterLink} from "vue-router";


export default defineComponent({
  // props: {
  // },
  setup(props, context) {

    const menuOptions: MenuOption[] = [
      {
        type: 'group',
        label: 'From JSON',
        key: 'FromJson',
        children: [
          {
            label: () =>
                h(
                    RouterLink,
                    {
                      to: {
                        name: 'Json2JavaBean',
                      }
                    },
                    {default: () => 'To JavaBean'}
                ),
            key: 'Json2JavaBean'
          },
          {
            label: () =>
                h(
                    RouterLink,
                    {
                      to: {
                        name: 'Json2Jsonschema'
                      }
                    },
                    {default: () => 'To Jsonschema'}
                ),
            key: 'Json2Jsonschema'
          },
        ]
      },
      {
        type: 'group',
        label: 'From JSON Schema',
        key: 'FromJsonSchema',
        children: [
          {
            label: () =>
                h(
                    RouterLink,
                    {
                      to: {
                        name: 'JsonSchema2JavaBean',
                      }
                    },
                    {default: () => 'To JavaBean'}
                ),
            key: 'JsonSchema2JavaBean'
          },
        ]
      },
      {
        type: 'group',
        label: 'From SQL',
        key: 'FromSQL',
        children: [
          {
            label: () =>
                h(
                    RouterLink,
                    {
                      to: {
                        name: 'Sql2Es',
                      }
                    },
                    {default: () => 'To ElasticSearch'}
                ),
            key: 'SQL2ES'
          },
        ]
      },
    ]

    return {
      menuOptions,
    }
  }
});
