import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_menu = _resolveComponent("n-menu")!
  const _component_n_layout_sider = _resolveComponent("n-layout-sider")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_n_layout_content = _resolveComponent("n-layout-content")!
  const _component_n_layout = _resolveComponent("n-layout")!

  return (_openBlock(), _createBlock(_component_n_layout, { "has-sider": "" }, {
    default: _withCtx(() => [
      _createVNode(_component_n_layout_sider, {
        "collapse-mode": "transform",
        "collapsed-width": 4,
        width: 240,
        "show-collapsed-content": false,
        "show-trigger": "arrow-circle",
        "content-style": "padding: 2rem;",
        bordered: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_n_menu, {
            mode: "vertical",
            options: _ctx.menuOptions
          }, null, 8, ["options"])
        ]),
        _: 1
      }),
      _createVNode(_component_n_layout_content, { "content-style": "padding: 24px;" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}